.user {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #120d35;
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userMainContainer {
  width: 50vw;
  min-width: 50vw;
}
