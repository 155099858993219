.main {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #120d35;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userMainContainer {
  width: 50vw;
  min-width: 50vw;
}

.wrapper {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 80vw;
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    flex-wrap: nowrap;
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 80vw;
  }
}
