.mainContainer {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #120d35;
  min-height: 100vh;
  color: white;
  scrollbar-width: none;
}

.luckiestGuy {
  font-family: "LuckiestGuy";
}

.title {
  font-family: "Roboto";
  font-weight: bold;
}

.subtitle {
  font-family: "Roboto";
  font-weight: 300;
}

.bodyText {
  font-family: "Roboto";
  font-weight: normal;
}

.bubbleText {
  font-family: "Poppins-Regular";
}

/**
  darkColor: #120d35
  mediumColor: #2B1A59
  lightColor: #3E276F
*/
