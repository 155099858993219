#mainContainer {
  display: flex;
  flex: 1;
}

#reader {
  display: flex;
  flex: 1;
  height: 100vh;

  justify-content: center;
}

#msgContainer {
  background-color: white;

  height: 100vh;
  min-height: 100vh;
  width: 50vw;
  min-width: 50vw;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  /* padding-bottom: 40px; */

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

#navbar {
  /* top: 10px; */
  /* position: absolute; */

  /* border-radius: 10px; */

  /* padding: 16px; */
  color: #fefefe;
  scrollbar-width: none;
}

#test {
  height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}

#msgContainer img {
  width: 100%;
}
