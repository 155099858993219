.btn-nbk {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-top: 40px;
}

.btn-small {
  width: 20vw;
  aspect-ratio: 530 / 70;
  border-radius: 4px;
}

.btn-medium {
  width: 30vw;
  aspect-ratio: 530 / 70;
  border-radius: 8px;
}

.btn-large {
  width: 40vw;
  aspect-ratio: 530 / 70;
  border-radius: 8px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .btn-small {
    width: 18vw;
  }

  .btn-medium {
    width: 28vw;
  }

  .btn-large {
    width: 42vw;
    aspect-ratio: 530 / 70;
    border-radius: 8px;
  }
}

@media (max-width: 992px) {
  .btn-small {
    width: 16vw;
  }

  .btn-medium {
    width: 26vw;
  }

  .btn-large {
    width: 39vw;
    aspect-ratio: 530 / 70;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .btn-small {
    width: 14vw;
  }

  .btn-medium {
    width: 24vw;
  }

  .btn-large {
    width: 36vw;
    aspect-ratio: 530 / 70;
    border-radius: 8px;
  }
}

@media (max-width: 576px) {
  .btn-small {
    width: 12vw;
  }

  .btn-medium {
    width: 22vw;
  }

  .btn-large {
    width: 50vw;
    aspect-ratio: 530 / 70;
    border-radius: 4px;
  }
}
