.txt-btn {
  color: white;
  font-size: 22px;
  font-family: "Roboto";
  font-weight: normal;
  word-wrap: break-word;
}

.title {
  color: #fff;
  font-family: "Poppins-Regular";
  font-size: 25px;
  line-height: normal;
}

.serie-title {
  color: white;
  font-size: 20px;
  font-family: "Poppins-BlackItalic";
  text-transform: uppercase;
  word-wrap: break-word;
}

.resume {
  color: white;
  font-size: 16px;
  font-family: "Poppins-Regular";
  word-wrap: break-word;
}

.episode-name {
  color: white;
  font-size: 16px;
  font-family: "Roboto";
  word-wrap: break-word;
}

.poppins-italic {
  color: "#1E1E1E";
  font-size: 11px;
  font-family: "Poppins-Italic";
  font-style: "italic";
  font-weight: "400";
  word-wrap: "break-word";
  text-align: left;
}
.poppins-semibold {
  color: "#1E1E1E";
  font-size: 11px;
  font-family: "Poppins-SemiBold";
  word-wrap: "break-word";
  text-align: left;
}

/* Media Queries */
@media (max-width: 1200px) {
  .txt-btn {
    font-size: 20px;
  }

  .title {
    font-size: 23px;
  }

  .serie-title {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .txt-btn {
    font-size: 18px;
  }

  .title {
    font-size: 21px;
  }

  .serie-title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .txt-btn {
    font-size: 16px;
  }

  .title {
    font-size: 19px;
  }

  .serie-title {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .txt-btn {
    font-size: 14px;
  }

  .title {
    font-size: 17px;
  }

  .serie-title {
    font-size: 16px;
  }

  .resume {
    font-size: 14px;
  }
}
