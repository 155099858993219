.serieContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.infos {
  max-width: 50vw;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 150px;
  margin-top: 5px;
}

#imgHeader {
  width: 50%;
  aspect-ratio: 518/331;
  align-self: center;
}

#badgeAge {
  padding: 5px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid white;
}

#containerEpRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  aspect-ratio: 500/128;
  background-color: #2b1a59;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

#imgContainerEpRow {
  width: 64%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

#epNumber {
  text-decoration: none;
  font-size: 40px;

  position: absolute;
  left: 15px;
  top: 10px;
}

#playEp {
  flex: 1.5;
  height: 7rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
